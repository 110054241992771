import React, { useEffect, useRef, useState } from 'react';

import { Button, ModalProps, Modal as AntModal, notification } from 'antd';

import { useTrans } from '@web/client/src/hooks/useTrans';
import { useActionAPI, useGetAPI } from '@web/shared/apis';
import EachInput from '@web/shared/components/form/EachInput';
import { IAnyObject } from '@web/shared/types/etc';

import { ModalStyled } from './signUpStyled';

interface IEmailValidation extends ModalProps {
  data: IAnyObject | null;
  closeModal: () => void;
  setCompleted: (v: boolean) => void;
}

let intervalHandler: NodeJS.Timeout;

export default function EmailValidation({
  data,
  setCompleted,
  closeModal,
  ...props
}: IEmailValidation) {
  const [inputValue, setInputValue] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [seconds, setSeconds] = useState<number>(180);
  const { data: tempData } = useGetAPI('temps', {
    enabled: Boolean(props.visible && confirmClicked),
    id: data?.id,
    qs: {
      key: inputValue,
    },
  });
  const { action } = useActionAPI('temps');
  const $ = useTrans();

  const onClickConfirm = async () => {
    setConfirmClicked(true);
  };

  useEffect(() => {
    if (confirmClicked) {
      if (tempData !== undefined) {
        const verified = tempData;
        if (verified) {
          setCompleted(true);
          closeModal();
          notification.success({
            message: `${$('이메일 인증이 완료되었습니다.')}`,
          });
          action('delete', null, data?.id);
        } else {
          setIsError(true);
        }
      }
      setConfirmClicked(false);
    }
  }, [tempData]);

  // 인증번호를 000000으로 고정하여 전송
  const onClickResend = async () => {
    // await action('put', { to: data?.to, lang: data?.lang }, data?.id);
    await action(
      'put',
      { to: data?.to, lang: data?.lang, key: '000000' },
      data?.id,
    ); // 인증번호를 000000으로 고정
    setInputValue('');
    setSeconds(180);
    notification.success({ message: `${$('재전송이 완료되었습니다.')}` });
  };

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const isVisible = props.visible;

  const prevVisible = usePrevious(isVisible);

  useEffect(() => {
    if (prevVisible !== isVisible) {
      setSeconds(180);
    }

    if (isVisible) {
      intervalHandler = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);

      if (seconds === 0) {
        clearInterval(intervalHandler);
        AntModal.error({
          title: `${$('시간 초과')}`,
          content: `${$('작성 가능한 시간을 초과하였습니다.')}`,
          onOk: closeModal,
          onCancel: closeModal,
        });
      }
    }
    return () => clearInterval(intervalHandler);
  }, [seconds, isVisible]);

  const timeDisplay = () => {
    const minutes = Math.floor(seconds / 60);
    const second = String(seconds % 60).padStart(2, '0');
    if (seconds === 0) {
      return `${$('시간이 초과되었습니다.')}`;
    }
    return `${$('분 초 남았습니다.', [`${minutes}`, `${second}`])}`;
  };
  const onEnter = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') {
      onClickConfirm();
    }
  };

  return (
    <ModalStyled
      modalID="EmailValidation"
      centered
      destroyOnClose
      title={`${$('이메일 인증')}`}
      width={400}
      onCancel={closeModal}
      {...props}
      maskClosable={false}
    >
      <div onKeyDown={onEnter}>
        <div className="info">
          <p>{data?.to}</p>
          <p>{`${$('로 인증번호를 전송했습니다.')}`}</p>
          <p>{`${$('3분 이내로 입력을 완료해주세요.')}`}</p>
          {data?.ip && (
            <p>{`${$('새로운 환경에서 로그인하여 인증이 필요합니다.')}`}</p>
          )}
        </div>

        <div className="time">{timeDisplay()}</div>

        <EachInput
          length={6}
          setInputValue={(v: string) => setInputValue(v)}
          isError={isError}
          setIsError={(v: boolean) => setIsError(v)}
        />

        {isError && (
          <div className="errorDiv">{`${$(
            '이메일로 받은 인증번호를 정확히 입력해주세요.',
          )}`}</div>
        )}

        <Button className="complete" onClick={onClickConfirm} type="primary">
          {`${$('인증 완료')}`}
        </Button>

        <div className="spam">
          {`${$('이메일을 받지 못하셨다면 스팸메일함을 확인해주세요.')}`}
        </div>

        <div>{`${$('인증번호가 전송되지 않았나요?')}`}</div>

        <div className="resend" onClick={onClickResend}>
          {`${$('인증번호 재전송')}`}
        </div>
      </div>
    </ModalStyled>
  );
}
